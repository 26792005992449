import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Subscription, lastValueFrom } from 'rxjs';


const Swal = require('sweetalert2')


@Component({
  selector: 'app-edicion-lead',
  templateUrl: './edicion-lead.component.html',
  styleUrls: ['./edicion-lead.component.scss']
})

export class EdicionLeadComponent implements OnInit {

    private jwt


  rubrosOpciones = []; 
  tipoEmpresaOpciones = []; 


  constructor(
    private http: HttpClient
  ) { 
      this.jwt = JSON.parse(localStorage.user).jwt
}

  async ngOnInit(){

      const data = new FormData()

      data.append('jwt', this.jwt)

      console.log(this.jwt)

      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_config_edicion_lead`, data))

      if (response.success) {


        this.rubrosOpciones = response.rubros_mbe;
        this.tipoEmpresaOpciones = response.tipos_empresa;

        
      }

      console.log(response);

  }

    agregarRubroModal() {

    Swal.fire({
      title: 'Nuevo Rubro',
      input: 'text',
      inputPlaceholder: 'Ingresa el rubro', 
      showCancelButton: true
    }).then(result => {

      console.log(result.value);

      if (result.value) {
        this.agregarRubro(result.value);
      }

    });

  }

  async agregarRubro(valor){

          const data = new FormData()
          data.append('name', valor)

          const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/insert_rubro`, data))

          if(response.success){
            
            this.rubrosOpciones = response.rubros_mbe;

          }

  }


  eliminar_rubro_modal(rubro_id) {
      Swal.fire({
          title: '¿Seguro que desea eliminar el rubro?',
          showCancelButton: true
      }).then(result => {

          this.eliminar_rubro(rubro_id);

          
      });
  }

  eliminar_tipo_empresa_modal(rubro_id) {
      Swal.fire({
          title: '¿Seguro que desea eliminar el Tipo de Empresa?',
          showCancelButton: true
      }).then(result => {

          this.eliminar_tipo_empresa(rubro_id);

          
      });
  }

    async eliminar_tipo_empresa(rubro_id){

      const data = new FormData()
      data.append('id', rubro_id)

      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/delete_tipo_empresa_mbe`, data))

      if(response.success){

        this.tipoEmpresaOpciones = response.tipos_empresa;

      }

  }

  async eliminar_rubro(rubro_id){

      const data = new FormData()
      data.append('id', rubro_id)

      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/delete_rubro_empresa_mbe`, data))

      if(response.success){

          this.rubrosOpciones = response.rubros_mbe;

      }

  }





    agregarTipoEmpresaModal() {

      Swal.fire({
        title: 'Nuevo Tipo de Empresa',
        input: 'text',
        inputPlaceholder: 'Ingresa el Tipo de Empresa', 
        showCancelButton: true
      }).then(result => {

        console.log(result.value);

        if (result.value) {
          this.agregarTipoEmpresa(result.value);
        }

      });

    }


  async agregarTipoEmpresa(valor){

          const data = new FormData()
          data.append('name', valor)

          const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/insert_tipo_empresa_mbe`, data))

          if(response.success){
            
            this.tipoEmpresaOpciones = response.tipos_empresa;

          }

  }

}
