    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Habilite o deshabilite las siguientes funcionalidades</h5>
                        <span>Estas funcionalidades afectarán las vistas.</span>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-concesionarios">
                                <thead>
                                    <tr>
                                        <th  scope="col"> Funcionalidades </th>
        
                                        <th scope="col"> Habilitar </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let f of funcionalidades">
                                        <td > <i  class="icofont {{f.icon}}"></i> {{f.funcionalidad}}</td>
                                        
                                        <td>
                                            <div class="toggle-container">

                                                

                                              <input type="checkbox" [(ngModel)]="f.habilitada" (change)="toggleChanged(f.id, f.habilitada)" id="toggle_{{ f.id }}">
                                              <label class="toggle-slider"  for="toggle_{{ f.id }}"></label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
