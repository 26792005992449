 <app-breadcrumb [title]="'Historial de comunicaciones'" [items]="['Registros']" [active_item]="'Whatsapp'">
</app-breadcrumb> 

<div class="go-back">
    <a (click)="go_back()"><i class="fa fa-arrow-circle-left"></i></a>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col call-chat-body">
            <div class="card">

<!-- 
                <div class="card-header bg-success">
                    <h5 class="text-uppercase"><i class="icofont icofont-brand-whatsapp"></i> Whatsapp</h5>
          
                </div>

                
                <div class="card-body" *ngIf="whatsapps.length > 0">
                        <ul class="crm-activity">
                            <li class="media" *ngFor="let whatsapp of whatsapps"><span class="me-3 font-primary">
                                    <span *ngIf="whatsapp.sender == 0">{{inicial_vendedor}}</span>
                                    <span *ngIf="whatsapp.sender == 1">{{inicial_cliente}}</span>
                                </span>
                                <div class="align-self-center media-body">
                                    <h6 class="mt-0">{{whatsapp.body}}</h6>
                                    <ul class="dates">
                                        <li class="digits">{{whatsapp.created_time}}</li>
                                    
                                    </ul>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <div class="card-body" *ngIf="whatsapps.length == 0">
                        <b>No se encontraron conversaciones.</b>
                    </div>
                </div>

             -->

             
            <div class="card">
                <div class="card-header bg-danger">
                    <h5 class="text-uppercase"><i class="icofont icofont-ui-call"></i> Llamadas telefónicas</h5>
                    <!-- <div class="card-header-right bg-success text-white">
                        <ul class="list-unstyled card-option">
                            <li><i class="fa fa-spin fa-cog"></i></li>
                        </ul>
                    </div> -->
                </div>
                <div class="card-body" *ngIf="calls.length > 0">
                    <ul class="list-group list-group-calls">
                        <li (click)="show_info_call(call.id)" *ngFor="let call of calls"
                            class="list-group-item d-flex justify-content-between align-items-center">Llamada hecha el:
                            {{call.created_time}}
                            <!-- <span class="badge badge-primary counter digits">{{call.diferencia_segundos}} segundos</span> -->
                        </li>
                    </ul>
                </div>
                <div class="card-body" *ngIf="calls.length == 0">
                    <b>No se encontraron llamadas telefónicas.</b>
                </div>
            </div>
            <div class="card">
                <div class="card-header bg-dark">
                    <h5 class="text-uppercase"><i class="fa fa-envelope"></i> Correo electrónico</h5>
                </div>
                <div class="card-body" *ngIf="mails.length > 0">
                    <ul class="list-group list-group-calls">
                        <li (click)="show_info_mail(mail.id)" *ngFor="let mail of mails"
                            class="list-group-item d-flex justify-content-between align-items-center">
                            Asunto: {{mail.body.mailing_asunto}}
                            <span class="badge badge-dark counter digits">{{mail.created_time}}</span>
                        </li>
                    </ul>
                </div>
                <div class="card-body" *ngIf="mails.length == 0">
                    <b>No se encontraron correos electrónicos enviados.</b>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->