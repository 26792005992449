import { Component, OnInit,Input, ViewEncapsulation, ViewChild, TemplateRef, AfterViewInit,  } from '@angular/core';
import { DatePipe } from '@angular/common';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, isAfter, isBefore} from 'date-fns';
import { Subject, Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';


import { CalendarEventTitleFormatter } from 'angular-calendar';
import { TranslateService } from '@ngx-translate/core';



const colors: any = {
  red: {
    primary: '#dc3545',
    secondary: '#d72536'
  },
  grease: {
    primary: '#959bb2',
    secondary: '#d72536'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#d72536'
  }
};


@Component({
  selector: 'app-modal-reprogramado-lead',
  templateUrl: './modal-reprogramado-lead.component.html',
  styleUrls: ['./modal-reprogramado-lead.component.scss']
})


export class ModalReprogramadoLeadComponent implements OnInit {

  @Input() private lead_id
  @Input() private jwt
  @ViewChild('flatpickrInput') flatpickrInput: any;
  @ViewChild('modalContent',{static: false})

  modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  fechaLeadModal: string = '';
  fechasByDay: { id: number, title:string, start:Date, lead_id:string }[] = []; 
  fecha_inicio: Date;

  


  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;

  constructor(private modal: NgbModal, 
    private http: HttpClient, 
    private datePipe: DatePipe, 
    private translate: TranslateService,
    private calendarEventTitleFormatter: CalendarEventTitleFormatter) { 

      translate.setDefaultLang('es'); // Establece el idioma predeterminado

    }

    // Colorear fechas pasadas
    beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
      const today = new Date();
    
      renderEvent.body.forEach((day) => {
        const currentDate = day.date;
    
        if (currentDate < today && day.inMonth) {
          day.cssClass = 'bg-light text-dark';
        }

        if (currentDate.toDateString() === today.toDateString()) {
          day.cssClass = ''; // Para asegurarte de que el día actual no tenga ninguna clase de CSS asignada
        }

      });
    }



    convertir_fecha (fecha) {

      return new Date(fecha);
        
    }

    async ngOnInit() {

      const data = new FormData()

      data.append('lead_id', this.lead_id)
      data.append('jwt', this.jwt)

      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_fechas_reprogramado`, data))
      if (response.success) {

        const reprogramados = [];

        // Recorre el array 'response'
        for (var item of response.casos_reprogramados) {
          
          const specificDate = new Date(item.start);
          const today = new Date();

          var color_call = colors.red;
          
          if (isBefore(specificDate, today) && !isSameDay(specificDate, today)) {
            // La fecha específica es anterior al día de hoy
            // Realiza aquí la lógica deseada para el caso de una fecha anterior
            color_call = colors.grease

          } else {
            // La fecha específica es igual o posterior al día de hoy
            // Realiza aquí la lógica deseada para el caso de una fecha igual o posterior
            color_call = colors.red
          }

          var event = {
            id: item.lead_id,
            start: new Date(item.start),
            title: item.title,
            color: color_call,
            draggable: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            }
          };

          reprogramados.push(event);
        }

        this.events = reprogramados;

      }


    
    }



    dayAndHoursClicked(event: CalendarWeekViewBeforeRenderEvent): void {
      // Accede a la información del día haciendo referencia a event.day
      const clickedDay = event;
      console.log('hola')
      console.log(clickedDay)
    
      // Realiza la lógica deseada con el día seleccionado
      // ...
    }

    async dayClicked({ date, events }: { date: Date; events: CalendarEvent[]}) {

      const today = new Date();

      if (isBefore(date, today) && !isSameDay(date, today)){
        console.log('Es antes de día de hoy')
        return; // Evitar que se realice alguna acción
      } else {
        
        console.log(date)

        this.fecha_inicio = date;
  
        var fecha_inicio = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss')
        var fecha_fin = this.datePipe.transform(date, 'yyyy-MM-dd 23:59:59')
  
  
        const fecha_form = new FormData()
  
        fecha_form.append('jwt', this.jwt)
        fecha_form.append('fecha_inicio', fecha_inicio)
        fecha_form.append('fecha_fin', fecha_fin)
    
        var response_fecha = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_fechas_reprogramado_by_day`, fecha_form))
  
        this.fechasByDay = response_fecha.casos_reprogramados;
  
        this.modal.open(this.modalContent, { size: 'xl' });
      }



      


      if (isSameMonth(date, this.viewDate)) {
        this.viewDate = date;
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
          events.length === 0
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
        }
      }
      
    }

    eventTimesChanged({
      event,
      newStart,
      newEnd
    }: CalendarEventTimesChangedEvent): void {
      event.start = newStart;
      event.end = newEnd;
      this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: CalendarEvent): void {
      this.modalData = { event, action };
      this.modal.open(this.modalContent, { size: 'xl' });
    }

    async eliminar_call_again(id, index) {
      if(id != 0){
        const form = new FormData()

        form.append('lead_id', id)
        form.append('jwt', this.jwt)
  
        const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/eliminar_call_again`, form))
  
        if(response.sucess){
          this.fechasByDay.splice(index, 1); 
          this.actualizar()
        }
      }


    }

    dateFilter = (date: Date): boolean => {
      const today = new Date();
      return isSameDay(date, today) || isAfter(date, today);
    };

    async actualizar () {
      var fecha_inicio = this.datePipe.transform(this.fecha_inicio , 'yyyy-MM-dd HH:mm:ss')
      var fecha_fin = this.datePipe.transform(this.fecha_inicio , 'yyyy-MM-dd 23:59:59')

      const fecha_form = new FormData()

      fecha_form.append('jwt', this.jwt)
      fecha_form.append('fecha_inicio', fecha_inicio)
      fecha_form.append('fecha_fin', fecha_fin)
   
      var response_fecha = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_fechas_reprogramado_by_day`, fecha_form))

      this.fechasByDay = response_fecha.casos_reprogramados;

      this.ngOnInit() 
    }
  
    async guardar_call_again() {

      const form = new FormData();

      var miArray = this.fechasByDay;

      for (let index = 0; index < miArray.length; index++) {

        var fechaInput = miArray[index].start.toString();

        console.log(miArray[index])

        var id_lead = miArray[index].lead_id.toString();
        var id = miArray[index].id.toString();
        var fecha = this.datePipe.transform(fechaInput, 'yyyy-MM-dd HH:mm:ss')


        form.append('id', id);
        form.append('lead_id', id_lead);
        form.append('call_again', fecha)
        form.append('jwt', this.jwt)

        try {

          var response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/guardar_call_again`, form))

          if (response.success) {

            miArray[index].id = parseInt(response.call_again_lead);

            form.delete('lead_id');
            form.delete('call_again');
            form.delete('jwt');

            this.actualizar()
          }
          
        } catch (error) {
          console.error('Error al guardar el call again:', error);

        }

      }

    }

    async addEvent() {

      var lead_id =  this.lead_id;

      const form = new FormData()

      form.append('lead_id', lead_id)
      form.append('jwt', this.jwt)

      form.append('tienda_id', JSON.parse(localStorage.tienda_selected))

      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_lead_detalle_by_id`, form))

      var nombre_lead = response.lead.nombres;
      var id_lead = response.lead.id;
      
      var miArray = this.fechasByDay
      
      var nuevo_evento = { 
              id: 0,
              lead_id: id_lead,
              title: nombre_lead,
              start: startOfDay(this.fecha_inicio),
              color: colors.red,
              draggable: true,
              resizable: {
                beforeStart: true,
                afterEnd: true
              }
      }
      
      miArray.push(nuevo_evento);

    }

}
