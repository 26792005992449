<div class="container">
    <div class="form-group">
        <h2>Historial de comunicaciones</h2>
    </div>
    <div class="comu">
        <h4>Whatsapp</h4>
        <div class="comu-detalle">
            <div *ngIf="history.whatsapp.length > 0">
                <p>Se encontraron un total de {{history.whatsapp.length}} mensajes.</p>
                <ul>
                    <li *ngFor="let h of history.whatsapp">
                        <div class="messages-whatsapp {{h.align}}">
                            {{h.body}}
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="history.whatsapp.length == 0">
                <p>No se encontró comunicación por whatsapp</p>
            </div>
        </div>
    </div>
    <div class="comu">
        <h4>Llamadas</h4>
        <div class="comu-detalle">
            <!-- <div *ngIf="history.calls.length > 0">
                <p>Se encontraron un total de {{history.calls.length}} llamadas.</p>
                <ul>
                    <li *ngFor="let h of history.calls" class="detail-call">
                        <div>
                            <p class="no-margin">Llamada realizada el día: {{h.llamada_inicio}}</p>
                            <p [innerHTML]="h.data"></p>
                            <p style="padding: 10px 15px" [innerHTML]="h.chatgpt"></p>
                        </div>
                    </li>
                </ul>
            </div> -->

            <div *ngIf="history.calls.length > 0">
                <div class="card">
                    <!-- <div class="card-header">
                        <h5>Llamadas</h5>
                    </div> -->
                    <div class="card-body vertical-tab">
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                            <!-- <li [ngbNavItem]="1">
                                <a ngbNavLink>Home</a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                                                aria-labelledby="list-home-list">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry.
                                                Lorem Ipsum has been
                                                the
                                                industry's standard dummy text ever since the 1500s, when an unknown
                                                printer
                                                took a galley of type
                                                and
                                                scrambled it to make a type specimen book. It has survived not only five
                                                centuries, but also the
                                                leap
                                                into electronic typesetting, remaining essentially unchanged. It was
                                                popularised in the 1960s with
                                                the
                                                software like Aldus PageMaker including versions of Lorem Ipsum.</div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li> -->


                            <li *ngFor="let h of history.calls" [ngbNavItem]="h.id">
                                <a ngbNavLink>{{h.llamada_inicio}}</a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                                                aria-labelledby="list-home-list">
                                                <p class="text-center" [innerHTML]="h.data"></p>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>
                </div>
            </div>


            <div *ngIf="history.calls.length == 0">
                <p>No se encontraron llamadas realizadas</p>
            </div>
        </div>
    </div>
    <div class="comu">
        <h4>Correos</h4>
        <div class="comu-detalle">
            <div *ngIf="history.mailing.length > 0">
                <p>Se encontraron un total de {{history.mailing.length}} correos electrónicos enviados.</p>
                <ul>
                    <li *ngFor="let h of history.mailing" class="detail-mail">
                        <div>
                            <p class="no-margin">Asunto: {{h.body.mailing_asunto}}</p>
                            <p class="no-margin">Tipo: {{h.mailing_type}}</p>
                            <small>Mensaje: <b>{{h.body.message}}</b></small>
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="history.mailing.length == 0">
                <p>No se encontraron correos electrónicos enviados.</p>
            </div>
        </div>
    </div>


    <div class="form-group buttons">
        <button class="btn btn-secondary" (click)="onCancel()">Cerrar</button>
    </div>

</div>