import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";



import { UsersService } from 'src/app/logueo/users.service'
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { lastValueFrom } from 'rxjs'
const Swal = require('sweetalert2')

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  protected userData
  public userName: string
  public userType: string
  public multi_rol: boolean

  public roles = []


  public profileImg: 'assets/images/dashboard/profile_honda.png';

  constructor(private http: HttpClient, public usersService: UsersService, public router: Router) {
    const userName = JSON.parse(localStorage.getItem("user")).nombres
    const userType = JSON.parse(localStorage.user).user_type
    const multi_rol = JSON.parse(localStorage.user).multi_rol
    if (userName) {
      this.userName = userName
      this.userType = userType
      this.multi_rol = multi_rol
    }


    this.roles[1] = 'Administrador de sistema'
    this.roles[2] = 'Vendedor'
    this.roles[3] = 'Jefe de tienda'
    this.roles[5] = 'Supervisor de tiendas'
    this.roles[7] = 'Social Media'
    this.roles[8] = 'Administrador'
    this.roles[9] = 'Escuela de Manejo'
    this.roles[10] = 'Postventa'
    this.roles[11] = 'Recall'
    this.roles[12] = 'Administrador Recall'
    this.roles[13] = 'Recall - Callcenter'
    this.roles[14] = 'Taller'
    this.roles[15] = 'Supervisor Contact Center'
    this.roles[16] = 'Jefe de tienda Post Venta'
    this.roles[20] = 'Supervisor Contact Center Post Venta'

  }


  logoutFunc() {
    // this.usersService.SignOut();
    console.log('✅ Logout')
    localStorage.removeItem('user');
    localStorage.removeItem('lead_comercial__filtros')
    localStorage.removeItem('tienda_selected')
    this.userName = null
    this.router.navigate(['auth/login']);
  }

  ngOnInit() {
  }

  async change_rol(rol_id) {
    const data = new FormData;
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('rol_id', rol_id)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/change_rol `, data))
    console.log('response change_rol :', response)
    if (response.success) {
      this.userData = response.data
      this.userData.user_type = this.roles[this.userData.user_type_id]
      this.userData.multi_rol = true
      localStorage.setItem('user', JSON.stringify(this.userData));

      window.location.href = environment.base_url

    }
  }

  async cambiarRol() {
    const data = new FormData;
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/get_roles_by_userID`, data))
    if (response.success) {
      const rol = await Swal.fire({
        title: 'Seleccione su rol',
        input: 'select',
        inputOptions: {
          'Roles': response.roles
        },
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              this.change_rol(value)
            }
          })
        }
      })
    }


  }

}
