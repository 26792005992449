import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";

import { ActivatedRoute, Router } from '@angular/router';

import { ModalProgramarLlamadaComponent } from '../modal-programar-llamada/modal-programar-llamada.component';


@Component({
  selector: 'app-modal-llamada',
  templateUrl: './modal-llamada.component.html',
  styleUrls: ['./modal-llamada.component.scss']
})
export class ModalLlamadaComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,    private http: HttpClient,    private modalService: NgbModal,    public router: Router,


    ) {}

  @Input() private lead_id
  @Input() private jwt
  @Input() private celular
  @Input() private tienda_id

  celular_lead: string 
  resumenLlamada: string = '';
  comunicacion: string = '';

  async ngOnInit() {

    const data = new FormData()

    data.append('lead_id', this.lead_id)
    data.append('jwt', this.jwt)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/insert_comunicacion_call_by_lead_id`, data))


    if (response.success = "true") {
      this.comunicacion = response.comunicacion;
    }


   this.celular_lead = this.celular


  }

    async programar_llamada() {
    const dialogRef = this.modalService.open(ModalProgramarLlamadaComponent,
      {
        size: 'xl'
      })
    dialogRef.componentInstance.lead_id = this.lead_id
    dialogRef.componentInstance.jwt = this.jwt
    dialogRef.componentInstance.tienda_id = this.tienda_id

    dialogRef.result.then((data) => {
      if (data.success) {
        const lead_id__selected = data.lead_id
        this.redireccionarConNuevoId(lead_id__selected)
      }
    })


  }



redireccionarConNuevoId(nuevoId = '') {
    const nuevaUrl = `/leads/tienda/13/${nuevoId}`;
    this.router.navigateByUrl(nuevaUrl);
    this.lead_id = nuevoId
}



  async guardarLlamada() {

    if (this.resumenLlamada.trim() !== '') {

      const data = new FormData()

      data.append('lead_id', this.lead_id)
      data.append('jwt', this.jwt)
      data.append('comunicacion', this.comunicacion)
      data.append('resumenLlamada', this.resumenLlamada)

      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/update_comunicacion_call_by_lead_id`, data))

      if(response.success){
        this.activeModal.close();
      }
     
    }
}

}
