import { Routes } from '@angular/router';
import { LeadsComponent } from 'src/app/components/leads/leads.component';
import { SelectTiendaComponent } from 'src/app/components/select-tienda/select-tienda.component';
import { LeadDetalleComponent } from 'src/app/components/lead-detalle/lead-detalle.component';
import { ReportesComponent } from 'src/app/reportes/reportes.component';
import { AsesorSelectTiendaComponent } from 'src/app/asesor-select-tienda/asesor-select-tienda.component';
import { RegistrarPedidoComponent } from 'src/app/components/registrar-pedido/registrar-pedido.component'
import { ColaDeRequerimientoComponent } from 'src/app/components/cola-de-requerimiento/cola-de-requerimiento.component';
import { RegistrosComponent } from 'src/app/components/registros/registros.component';
import { LeadWhatsappComponent } from 'src/app/components/lead-whatsapp/lead-whatsapp.component';
import { LeadMailComponent } from 'src/app/components/lead-mail/lead-mail.component';
import { UsersComponent } from 'src/app/components/users/users.component';
import { UsersDescansoComponent } from 'src/app/components/users-descanso/users-descanso.component';
import { UsersActividadComponent } from 'src/app/components/users-actividad/users-actividad.component';
import { ConcesionariosComponent } from 'src/app/components/concesionarios/concesionarios.component';
import { DashboardSesionComponent } from 'src/app/components/dashboard-sesion/dashboard-sesion.component';
import { CreateLeadComponent } from 'src/app/create-lead/create-lead.component';
import { JefeSelectTiendaComponent } from 'src/app/jefe-select-tienda/jefe-select-tienda.component';
import { ContactCenterComponent } from 'src/app/components/contact-center/contact-center.component';
import { SelectTiendaContactCenterComponent } from 'src/app/components/select-tienda-contact-center/select-tienda-contact-center.component'
import { HistoryComunicationComponent } from 'src/app/history-comunication/history-comunication.component';

import { ConfiguracionCrmComponent } from 'src/app/configuracion-crm/configuracion-crm.component';

import { EdicionLeadComponent } from 'src/app/edicion-lead/edicion-lead.component'

export const content: Routes = [

  {
    path: '',
    component: ReportesComponent,
  },
  {
    path: 'edicion-lead',
    component: EdicionLeadComponent,
  },
  {
    path: 'dashboard',
    component: ReportesComponent,
  },
  {
    path: 'configuracion',
    component: ConfiguracionCrmComponent,
  },
  {
    path: 'contact_center',
    component: ContactCenterComponent,
  },

  {
    path: 'dashboard/sesiones',
    component: DashboardSesionComponent,
  },
  {
    path: 'dashboard/:rol',
    component: ReportesComponent,
  },
  {
    path: 'leads',
    component: LeadsComponent,
  },
  {
    path: 'leads/tienda',
    component: SelectTiendaComponent
  },
  {
    path: 'contact-center/tienda',
    component: SelectTiendaContactCenterComponent
  },
  {
    path: 'contact-center/tienda/:tienda_id',
    component: SelectTiendaContactCenterComponent
  },
  {
    path: 'leads/tienda/:tienda_id',
    component: SelectTiendaComponent
  },
  {
    path: 'leads/tienda/:tienda_id/:lead_id',
    component: LeadDetalleComponent
  },
  {
    path: 'leads/tienda/:tienda_id/:lead_id/whatsapp',
    component: LeadWhatsappComponent
  },
  {
    path: 'leads/tienda/:tienda_id/:lead_id/mail',
    component: LeadMailComponent
  },
  {
    path: 'asesor-select-tienda',
    component: AsesorSelectTiendaComponent
  },
  {
    path: 'jefe-select-tienda',
    component: JefeSelectTiendaComponent
  },
  {
    path: 'registrar-pedido',
    component: RegistrarPedidoComponent
  },
  {
    path: 'cola-de-requerimiento',
    component: ColaDeRequerimientoComponent
  },
  {
    path: 'registros/:nombre',
    component: RegistrosComponent
  },
  {
    path: 'users',
    component: UsersComponent
  },
  {
    path: 'users/:usuario_id',
    component: UsersComponent
  },
  {
    path: 'users/:usuario_id/actividad',
    component: UsersActividadComponent
  },

  {
    path: 'users/:usuario_id/descanso',
    component: UsersDescansoComponent
  },
  {
    path: 'concesionarios',
    component: ConcesionariosComponent
  },
  {
    path: 'concesionarios/:concesionario_id',
    component: ConcesionariosComponent
  },
  {
    path: 'create-lead',
    component: CreateLeadComponent
  },

  {
    path: 'widgets',
    loadChildren: () => import('../../components/widgets/widgets.module').then(m => m.WidgetsModule)
  },
  {
    path: 'ui-kits',
    loadChildren: () => import('../../components/ui-kits/ui-kits.module').then(m => m.UiKitsModule)
  },
  {
    path: 'base',
    loadChildren: () => import('../../components/bonus-ui/base/base.module').then(m => m.BaseModule)
  },
  {
    path: 'leads/tienda/:tienda_id/:lead_id/history',
    component: HistoryComunicationComponent
  },
  {
    path: 'advance',
    loadChildren: () => import('../../components/bonus-ui/advance/advance.module').then(m => m.AdvanceModule)
  },
  {
    path: 'project',
    loadChildren: () => import('../../components/apps/project/project.module').then(m => m.ProjectModule)
  },
  {
    path: 'ecommerce',
    loadChildren: () => import('../../components/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule)
  },
  {
    path: 'email',
    loadChildren: () => import('../../components/apps/email/email.module').then(m => m.EmailModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('../../components/apps/chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'user',
    loadChildren: () => import('../../components/apps/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'bookmarks',
    loadChildren: () => import('../../components/apps/bookmarks/bookmarks.module').then(m => m.BookmarksModule)
  },
  {
    path: 'file-manager',
    loadChildren: () => import('../../components/apps/file-manager/file-manager.module').then(m => m.FileManagerModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('../../components/apps/contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: 'tasks',
    loadChildren: () => import('../../components/apps/tasks/tasks.module').then(m => m.TasksModule)
  },
  {
    path: 'calender',
    loadChildren: () => import('../../components/apps/calender/calender.module').then(m => m.CalenderModule)
  },
  {
    path: 'social-app',
    loadChildren: () => import('../../components/apps/social-app/social-app.module').then(m => m.SocialAppModule)
  },
  {
    path: 'to-do',
    loadChildren: () => import('../../components/apps/todo/todo.module').then(m => m.TodoModule),
  },
  {
    path: 'buttons',
    loadChildren: () => import('../../components/buttons/buttons.module').then(m => m.ButtonsModule)
  },
  {
    path: 'to-do-firebase',
    loadChildren: () => import('../../components/apps/to-do-firebase/to-do-firebase.module').then(m => m.ToDoFirebaseModule)
  },
  {
    path: 'editor',
    loadChildren: () => import('../../components/editors/editor.module').then(m => m.EditorModule)
  },
  {
    path: 'chart',
    loadChildren: () => import('../../components/charts/charts.module').then(m => m.ChartModule),
  },
  {
    path: 'icons',
    loadChildren: () => import('../../components/icons/icons.module').then(m => m.IconsModule)
  },
  {
    path: 'form',
    loadChildren: () => import('../../components/forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'table',
    loadChildren: () => import('../../components/table/table.module').then(m => m.TableModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('../../components/cards/cards.module').then(m => m.CardsModule)
  },
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/others/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('../../components/apps/gallery/gallery.module').then(m => m.GalleryDemoModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('../../components/apps/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('../../components/apps/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'job',
    loadChildren: () => import('../../components/apps/job-search/job-search.module').then(m => m.JobSearchModule)
  },
  {
    path: 'learning',
    loadChildren: () => import('../../components/apps/learning/learning.module').then(m => m.LearningModule)
  },
  {
    path: 'knowledgebase',
    loadChildren: () => import('../../components/apps/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule)
  },
  {
    path: 'map',
    loadChildren: () => import('../../components/apps/map/map.module').then(m => m.MapModule)
  },
  {
    path: 'support-ticket',
    loadChildren: () => import('../../components/apps/support-ticket/support-ticket.module').then(m => m.SupportTicketModule)
  },
  {
    path: 'search-pages',
    loadChildren: () => import('../../components/others/search-result/search-result.module').then(m => m.SearchResultModule)
  },

];
