import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-usuario-tiempo-promedio',
  templateUrl: './dashboard-usuario-tiempo-promedio.component.html',
  styleUrls: ['./dashboard-usuario-tiempo-promedio.component.scss']
})
export class DashboardUsuarioTiempoPromedioComponent implements OnInit {
  @Input() data: {
    tiempo_promedio_primera_apertura : '',
    tiempo_promedio_primera_edicion : '',
    tiempo_promedio_llamada : ''
  }
  constructor() { 


  }

  ngOnInit(): void {
    console.log('hola')
  }

}
