import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from 'rxjs'
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})


export class ContactCenterService {

  private list_of_campus
  constructor(private http: HttpClient) { }

  async lead_service_init(campus_id: Number) {

    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('campus_id', `${campus_id}`)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_payload_init`, data))

    return response

  }

  async contact_center_init(campus_id: Number) {

    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.getItem("user")).jwt)
    data.append('campus_id', `${campus_id}`)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_payload_init`, data))

    return response

  }

}
