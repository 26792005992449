export const multi = [
  {
    "name": "08",
    "series": [
      {
        "name": "Lunes",
        "value": 20
      },
      {
        "name": "Martes",
        "value": 30
      },
      {
        "name": "Miércoles",
        "value": 10
      },
      {
        "name": "Jueves",
        "value": 20
      },
      {
        "name": "Viernes",
        "value": 30
      },
      {
        "name": "Sábado",
        "value": 40
      },
      {
        "name": "Domingo",
        "value": 40
      }
    ]
  },
  {
    "name": "09",
    "series": [
      {
        "name": "Lunes",
        "value": 30
      },
      {
        "name": "Martes",
        "value": 30
      },
      {
        "name": "Miércoles",
        "value": 20
      },
      {
        "name": "Jueves",
        "value": 10
      },
      {
        "name": "Viernes",
        "value": 30
      },
      {
        "name": "Sábado",
        "value": 20
      },
      {
        "name": "Domingo",
        "value": 20
      }
    ]
  },
  {
    "name": "10",
    "series": [
      {
        "name": "Lunes",
        "value": 10
      },
      {
        "name": "Martes",
        "value": 30
      },
      {
        "name": "Miércoles",
        "value": 20
      },
      {
        "name": "Jueves",
        "value": 43
      },
      {
        "name": "Viernes",
        "value": 54
      },
      {
        "name": "Sábado",
        "value": 30
      },
      {
        "name": "Domingo",
        "value": 30
      }
    ]
  },
  {
    "name": "11",
    "series": [
      {
        "name": "Lunes",
        "value": 30
      },
      {
        "name": "Martes",
        "value": 30
      },
      {
        "name": "Miércoles",
        "value": 30
      },
      {
        "name": "Jueves",
        "value": 30
      },
      {
        "name": "Viernes",
        "value": 30
      },
      {
        "name": "Sábado",
        "value": 30
      },
      {
        "name": "Domingo",
        "value": 30
      }
    ]
  },
  {
    "name": "12",
    "series": [
      {
        "name": "Lunes",
        "value": 30
      },
      {
        "name": "Martes",
        "value": 50
      },
      {
        "name": "Miércoles",
        "value": 30
      },
      {
        "name": "Jueves",
        "value": 20
      },
      {
        "name": "Viernes",
        "value": 10
      },
      {
        "name": "Sábado",
        "value": 0
      },
      {
        "name": "Domingo",
        "value": 0
      }
    ]
  },
  {
    "name": "13",
    "series": [
      {
        "name": "Lunes",
        "value": 15
      },
      {
        "name": "Martes",
        "value": 23
      },
      {
        "name": "Miércoles",
        "value": 22
      },
      {
        "name": "Jueves",
        "value": 22
      },
      {
        "name": "Viernes",
        "value": 23
      },
      {
        "name": "Sábado",
        "value": 0
      },
      {
        "name": "Domingo",
        "value": 0
      }
    ]
  },
  {
    "name": "14",
    "series": [
      {
        "name": "Lunes",
        "value": 34
      },
      {
        "name": "Martes",
        "value": 23
      },
      {
        "name": "Miércoles",
        "value": 32
      },
      {
        "name": "Jueves",
        "value": 45
      },
      {
        "name": "Viernes",
        "value": 46
      },
      {
        "name": "Sábado",
        "value": 0
      },
      {
        "name": "Domingo",
        "value": 0
      }
    ]
  },
  {
    "name": "15",
    "series": [
      {
        "name": "Lunes",
        "value": 0
      },
      {
        "name": "Martes",
        "value": 32
      },
      {
        "name": "Miércoles",
        "value": 26
      },
      {
        "name": "Jueves",
        "value": 15
      },
      {
        "name": "Viernes",
        "value": 10
      },
      {
        "name": "Sábado",
        "value": 0
      },
      {
        "name": "Domingo",
        "value": 0
      }
    ]
  },
  {
    "name": "16",
    "series": [
      {
        "name": "Lunes",
        "value": 24
      },
      {
        "name": "Martes",
        "value": 24
      },
      {
        "name": "Miércoles",
        "value": 24
      },
      {
        "name": "Jueves",
        "value": 24
      },
      {
        "name": "Viernes",
        "value": 24
      },
      {
        "name": "Sábado",
        "value": 0
      },
      {
        "name": "Domingo",
        "value": 0
      }
    ]
  },
  {
    "name": "17",
    "series": [
      {
        "name": "Lunes",
        "value": 13
      },
      {
        "name": "Martes",
        "value": 13
      },
      {
        "name": "Miércoles",
        "value": 13
      },
      {
        "name": "Jueves",
        "value": 13
      },
      {
        "name": "Viernes",
        "value": 13
      },
      {
        "name": "Sábado",
        "value": 13
      },
      {
        "name": "Domingo",
        "value": 0
      }
    ]
  },
  {
    "name": "18",
    "series": [
      {
        "name": "Lunes",
        "value": 23
      },
      {
        "name": "Martes",
        "value": 23
      },
      {
        "name": "Miércoles",
        "value": 23
      },
      {
        "name": "Jueves",
        "value": 23
      },
      {
        "name": "Viernes",
        "value": 23
      },
      {
        "name": "Sábado",
        "value": 23
      },
      {
        "name": "Domingo",
        "value": 0
      }
    ]
  },
  {
    "name": "19",
    "series": [
      {
        "name": "Lunes",
        "value": 0
      },
      {
        "name": "Martes",
        "value": 30
      },
      {
        "name": "Miércoles",
        "value": 23
      },
      {
        "name": "Jueves",
        "value": 15
      },
      {
        "name": "Viernes",
        "value": 24
      },
      {
        "name": "Sábado",
        "value": 0
      },
      {
        "name": "Domingo",
        "value": 0
      }
    ]
  },
  {
    "name": "20",
    "series": [
      {
        "name": "Lunes",
        "value": 30
      },
      {
        "name": "Martes",
        "value": 25
      },
      {
        "name": "Miércoles",
        "value": 27
      },
      {
        "name": "Jueves",
        "value": 46
      },
      {
        "name": "Viernes",
        "value": 22
      },
      {
        "name": "Sábado",
        "value": 3
      },
      {
        "name": "Domingo",
        "value": 0
      }
    ]
  }
]