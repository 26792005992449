import { Component, ViewChild, OnInit } from "@angular/core";

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexXAxis
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  stroke: any; // ApexStroke;
  dataLabels: any; // ApexDataLabels;
  fill: ApexFill;
  tooltip: ApexTooltip;
  colors?: string[];
};

@Component({
  selector: 'app-combo-chart',
  templateUrl: './combo-chart.component.html',
  styleUrls: ['./combo-chart.component.scss']
})
export class ComboChartComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      
      series: [
        {
          name: "Días de conexión",
          type: "column",
          data: [21, 25, 25, 20,  12]
        },
        {
          name: "Acciones",
          type: "line",
          data: [ 204, 267 , 300 , 353, 129] 
        }
      ],
      colors: ['#ff102a', '#FFA69B'], 
      chart: {
        height: 800,
        type: "line",
       
      },
      
      stroke: {
        width: [0, 4]
      },
      title: {
        text: "Actividad de Jeremías Smith en la Web"
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      labels: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo"
      ],
      xaxis: {
        type: "category"
      },
      yaxis: [
        {
          title: {
            text: "Días"
          }
        },
        {
          opposite: true,
          title: {
            text: "Acciones"
          }
        }
      ]
    };
  }




  ngOnInit(): void {
    console.log('hola')
  }

}
