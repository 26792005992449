<div class="container-fluid">


	<app-breadcrumb [title]="'Configuración Edición de Leads '" [items]="['Registros']" [active_item]="'Configuración Leads'"></app-breadcrumb>


	<label>
	Este módulo te permite editar las opciones disponibles en ciertos campos cuando estás editando la información de leads.
	</label>

    
    <div class="form-group row col-12 mt-8" >

    

				<div class="form-group col-6 row m-2">
				    <div class="card card-body col-10">
				        <h5>Rubro de empresa Opciones:</h5>
				        <div class="col-12 m-1 row" *ngFor="let rubro of rubrosOpciones">
				            <div class="col-8">
				                <input class="form-control" type="text" value="{{rubro.name}}" disabled>
				            </div>
				            <div class="col-4">
				                <button class="btn btn-danger" (click)="eliminar_rubro_modal(rubro.id)">
				                    x
				                </button>
				            </div>
				        </div>

					    <div class="col-2 mt-3">
					        <button class="btn btn-danger" (click)="agregarRubroModal()">
					            + Agregar 
					        </button>
					    </div>
				    </div>
				</div>


				<div class="form-group col-6 row m-2">
				    <div class="card card-body col-10">
				        <h5>Tipo de empresa Opciones:</h5>
				        <div class="col-12 m-1 row" *ngFor="let tipo_empresa of tipoEmpresaOpciones">
				            <div class="col-8">
				                <input class="form-control" type="text" value="{{tipo_empresa.name}}" disabled>
				            </div>
				            <div class="col-4">
				                <button class="btn btn-danger" (click)="eliminar_tipo_empresa_modal(tipo_empresa.id)">
				                    x
				                </button>
				            </div>
				        </div>

					    <div class="col-2 mt-3">
					        <button class="btn btn-danger" (click)="agregarTipoEmpresaModal()">
					            + Agregar 
					        </button>
					    </div>
				    </div>
				</div>


    </div>

</div>