import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-usuarios-hora-actividad',
  templateUrl: './dashboard-usuarios-hora-actividad.component.html',
  styleUrls: ['./dashboard-usuarios-hora-actividad.component.scss']
})
export class DashboardUsuariosHoraActividadComponent implements OnInit {

  @Input() semana = ''
  @Input() heatMapData = []



  colorScheme = {
    domain:
      ['#f26171', '#f04255', '#ff102a', '#aa0013', '#67020d']
  };


  daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  


  make_filter() {
    
    console.log(this.semana)

  }

  constructor() {

  }

  ngOnInit() {

  }



}
