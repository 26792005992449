import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { ChatService } from '../../shared/services/chat.service';


@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private tienda_id
  private lead_id
  private last_whatsapp_response
  public disponible_mandarwhatsapp = false
  message: string;
  lead
  public openTab: string = "call";
  public chatUser: any;
  public profile: any;
  public chats: any;
  public chatText: string;
  public error: boolean = false;
  public notFound: boolean = false;
  public id: any;
  public searchText: string;
  public showEmojiPicker: boolean = false;
  public emojies: any;
  public mobileToggle: boolean = false

  io = io('http://localhost:3000/',{
    withCredentials: true,
    autoConnect: true
  });

  constructor(private chatService: ChatService) { 

   // this.sendMessage({text: "hola", messageType: 1})
    
  }

  
  sendMessage(messageInfo: { lead_id: string, tienda_id: number, comunicacion_type: string, jwt: string }) {
    
    this.io.emit("iniciarConsulta", messageInfo);

  }

  getComunicacion(){

    this.io.on("datosActualizados", (response) =>{

      console.log(response);

      console.log('se actualizó la comunicación');

      const cantidad_mensajes = response.historial[0].message.length;

      if (this.last_whatsapp_response != cantidad_mensajes) {
        this.last_whatsapp_response = cantidad_mensajes
        this.disponible_mandarwhatsapp = cantidad_mensajes.whatsapp
        this.chatService.chatToUser(1).subscribe(chatUser => this.chatUser = chatUser)
        this.chatService.getChatHistory(1, cantidad_mensajes.historial).subscribe(chats => this.chats = chats)
      }

    });

    this.io.on("datosNoActualizados", () =>{
      console.log('No se actualizó la comunicación')
    });

  }
  
}
