import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs'
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-leads-por-concesionario',
  templateUrl: './dashboard-leads-por-concesionario.component.html',
  styleUrls: ['./dashboard-leads-por-concesionario.component.scss']
})
export class DashboardLeadsPorConcesionarioComponent implements OnInit {

  @Input() data: any = []


  protected jwt
  private routeSub: Subscription;
  view: any[] = null
  rol: String

  // options
  showXAxis = true
  showYAxis = true
  gradient = false
  showLegend = true
  showXAxisLabel = true
  xAxisLabel = ''
  showYAxisLabel = true
  yAxisLabel = 'Concesionarios'

  colorScheme = {
    domain: ['#f26171', '#f04255', '#ff102a', '#aa0013', '#67020d']
    // domain : ['#ff102a','#000000','#626e7a']
  };

  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute,) {
    this.jwt = JSON.parse(localStorage.getItem('user')).jwt
  }

  ngOnInit(): void {
    if(this.data === undefined){
      this.data = []
    }
  }


  onSelect(event) {
    console.log(event);
  }

  receivedData: any;

  receiveData(data: any) {
    this.receivedData = data;
  }
  showData() {
    // console.log(this.data)
  }
}
