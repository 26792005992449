<app-breadcrumb [title]="'Tiendas'" [items]="['Concesionarios']"></app-breadcrumb>
<div *ngIf="concesionario_id > 0; then edit_conce else pick_conce"></div>
<ng-template #pick_conce>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <!-- <h5></h5> -->
                       <!-- <button class="btn btn-primary" (click)="agregarConcesionario(content)">Agregar</button> -->
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-concesionarios">
                                <thead>
                                    <tr>
                                        <th class="text-center" scope="col">Nombre</th>
                                        <th class="text-center" scope="col">Dirección</th>
                                       
                                        <th class="text-center" scope="col">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let c of concesionarios">
                                        <td class="text-center">{{c.name}}</td>
                                        <td class="text-center">{{c.direccion}}</td>
                    

                                        <td class="text-center">
                                            <a [routerLink]="[c.id]"><i class="fa fa-edit"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #edit_conce>
    <div class="container-fluid">
        <button (click)="vincularUsuarioModal(content)" class="btn btn-primary">+ Vincular usuario</button>
        <div class="m-t-30 row ui-sortable">
            <h6><u>Asesores de venta:</u></h6>
            <div class="col-sm-12" *ngFor="let usu of usuarios; let i = index">
                <div *ngIf="usu.user_type_id == 2">
                    <div class="card card-temporal">
                        <div class="card-body megaoptions-border-space-sm">
                            <div class="row">
                                <div class="col-4">Vendedor</div>
                                <div class="col-8"><span class="badge badge-primary">{{usu.name}}
                                        {{usu.last_name}}</span></div>
                            </div>
                            <div class="row">
                                <div class="col-4">Cantidad de leads (actuales)</div>
                                <div class="col-8">{{usu.cantidad_leads}}</div>
                            </div>
                            <div class="row">
                                <div class="col-4">Asignación</div>
                                <div class="col-8"><input type="text" class="form-control"
                                        [(ngModel)]="usuarios[i].asignacion">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">Habilitar
                                    <i ngbTooltip="Si deshabilitas al usuario de esta tienda, no podrá ver los leads de esta tienda."
                                        class="fa fa-fw fa-info-circle"></i>
                                </div>
                                <div class="col-8">
                                    <label class="switch">
                                        <input type="checkbox" [(ngModel)]="usuarios[i].estado_en_conce"
                                            [attr.checked]="usuarios[i].estado_en_conce == 1 ? '' : null"><span
                                            class="switch-state"></span>
                                    </label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">Acción</div>
                                <div class="col-8">
                                    <div class="acciones">
                                        <button (click)="removeFromConce(usu.id)" class="btn btn-secondary">Eliminar <i
                                                class="icon-eraser"></i></button>
                                        <button (click)="actualizarVendedor(usu.id)" class="btn btn-primary ">Actualizar
                                            <i class="icon-pencil"></i></button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <h6><u>Usuarios:</u></h6>
            <div class="col-sm-12 m-t-10" *ngFor="let usu of usuarios">
                <div *ngIf="usu.user_type_id != 2">
                    <div class="card card-temporal">
                        <div class="card-body megaoptions-border-space-sm">
                            <div class="row">
                                <div class="col-4">Rol</div>
                                <div class="col-8">{{usu.nombre_rol}}</div>
                            </div>
                            <div class="row">
                                <div class="col-4">Nombre: </div>
                                <div class="col-8"><span class="badge badge-primary">{{usu.name}}
                                        {{usu.last_name}}</span></div>
                            </div>
                            <div class="row">
                                <div class="col-4">Acción</div>
                                <div class="col-8">
                                    <div class="acciones">
                                        <button (click)="removeFromConce(usu.id)"
                                            class="btn btn-secondary">Eliminar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="opciones m-t-30">
            <small class="d-block m-t-15">*El número ingresado en asignación tienen que ser entero, no se acepta
                decimales y el orden en el que esté significa de cómo comenzará la asignación.</small>
            <small class="d-block m-b-30">Ejemplo: Si el vendedor que se encuentra en lo más alto del listado tiene
                número 6, se le asignarán 6 leads y después se procederá con el segundo de la lista hasta que complete
                la cantidad de leads que tenga y asi sucesivamente.</small>
        </div>
    </div>
</ng-template>