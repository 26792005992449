<div class="container">
    <div class="form-group">
        <h2>Detalles de la llamada:</h2>
    </div>
    <div class="row">
        <!-- <div class="col-12 col-md-12">
            <h5>Llamada en audio:</h5>
            <audio controls *ngIf="comunicacion.attachment">
                <source src="{{comunicacion.attachment}}" type="audio/mpeg">
                <source src="{{comunicacion.attachment}}" type="audio/ogg">
              Your browser does not support the audio element.
              </audio>
        </div> -->

        <!--

        <div class="col-sm-12 col-xl-12">
            <div class="card card-absolute">

        
                <div class="card-header bg-primary">
                    <h5 class="text-white">Llamada en audio:</h5>
                </div>

                <div class="card-body">
                    <audio id="audio" controls *ngIf="comunicacion.attachment">
                        <source src="{{comunicacion.attachment}}" type="audio/mpeg">
                        <source src="{{comunicacion.attachment}}" type="audio/ogg">
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </div>
        </div>
        --> 

        <div class="col-sm-12 col-xl-12">

            <div class="card card-absolute">
                <div class="card-header bg-primary">
                    <h5 class="text-white">Feedback De Llamada:</h5>
                </div>
                <div class="card-body">
                    <ul>
                        <li *ngFor="let message of comunicacion.data">{{message}}</li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>