import { Component, OnInit, AfterViewInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { ModalCreateLeadComponent } from '../modal-create-lead/modal-create-lead.component';


const Swal = require('sweetalert2')
@Component({
  selector: 'app-create-lead',
  templateUrl: './create-lead.component.html',
  styleUrls: ['./create-lead.component.scss']
})

export class CreateLeadComponent implements OnInit, AfterViewInit {

  private jwt
  user_type_id
  leads = []



  constructor(
    private http: HttpClient,
    private modalService: NgbModal
  ) {
    this.jwt = JSON.parse(localStorage.user).jwt
    this.user_type_id = JSON.parse(localStorage.user).user_type_id
  }
  async ngAfterViewInit() {
    const data = new FormData()
    data.append('jwt', this.jwt)
    var response 
    if(this.user_type_id == 2){
      response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_my_leads_created`, data))
    }else{
      response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPI/get_my_leads_created_jefe`, data))
    }
    
    if (response.success) {
      this.leads = response.leads
      console.log(this.leads)
    }
  }

  ngOnInit() {


  }

  open_modal() {
    console.log('crear_lead_modal')
    const dialogRef = this.modalService.open(ModalCreateLeadComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      if (data.success) {
        Swal.fire(data.message, '', 'success')
        this.leads = data.leads
      }
    })
  }

  open_modal_jefe() {
    console.log('crear_lead_modal_jefe')
    const dialogRef = this.modalService.open(ModalCreateLeadComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      if (data.success) {
        Swal.fire(data.message, '', 'success')
        this.leads = data.leads
      }
    })
  }

}
