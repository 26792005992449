

<div class="container">
    <div class="form-group">
        <h2>Enviar cotización</h2>
    </div>
    <div class="form-group">
        <label for="subject">Asunto:</label>
        <input id="subject" class="form-control" type="text" [(ngModel)]="subject">
    </div>
    <div class="form-group">
        <label id="message" for="message">Mensaje:</label>
        <textarea id="message" cols="30" rows="10" class="form-control" [(ngModel)]="message"></textarea>
    </div>

    <div class="form-group buttons">
        <button class="btn btn-primary mr-2" (click)="adjuntarArchivo = true; crearArchivo = false" [disabled]="adjuntarArchivo">Adjuntar archivo</button>
        <button class="btn btn-primary" (click)="crearArchivo = true; adjuntarArchivo = false" [disabled]="crearArchivo">Crear archivo</button>
    </div>
    
    <!-- Campo para adjuntar archivo -->
    <div class="form-group" *ngIf="adjuntarArchivo">
      
            <ngx-dropzone (change)="onSelect($event)" [multiple]="false">
                <ngx-dropzone-label>
                    <div class="dz-message needsclick">
                        <i class="icon-cloud-up"></i>
                        <h6>Arrastre aquí el archivo que desea enviar.</h6>
                        <span>El archivo se enviará al correo electrónico del cliente</span>
                        <span class="bold">(Límite: 5MB)</span>
                    </div>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
    
    </div>

    
    <div class="form-group" *ngIf="crearArchivo">
        
        <div class="row">
            <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="ciudad">Nombre del Cliente:</label>
                        <input type="text" class="form-control" id="nombre_cliente" disabled [(ngModel)]="datos_cotizacion.nombreCliente">
                    </div>
            </div>   
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="ciudad">Servicio de Interés Cliente:</label>
                    <input type="text" class="form-control" id="servicio_interes" disabled [(ngModel)]="datos_cotizacion.servicio_interes">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="ciudad">Tienda:</label>
                        <input type="text" class="form-control" id="tienda" disabled [(ngModel)]="datos_cotizacion.tienda">
                    </div>
            </div>   
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label for="ciudad">Cotización:</label>
                    <input type="number" class="form-control" id="cotizacion"  [(ngModel)]="datos_cotizacion.cotizacion">
                </div>
            </div>
        </div>

    </div>

    

    <div class="form-group buttons">
        <button class="btn btn-primary" (click)="onSubmit()">Enviar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>
</div>
