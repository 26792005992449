<div class="container">
    <div class="form-group">
        <h2>Crear lead</h2>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombres">Nombres</label>
                <input type="text" class="form-control" id="nombres" [(ngModel)]="lead_to_create.nombres">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="correo_electronico">Correo electrónico</label>
                <input type="text" class="form-control" id="correo_electronico"
                    [(ngModel)]="lead_to_create.correo_electronico">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="celular">Celular</label>
              

                <intl-telephone-input
                id="celular"
            
                [preferredCountries]="[CountryISO.Peru]"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [separateDialCode]="false"
                [enableAutoCountrySelect]="true"
                [searchCountryField]="SearchCountryField.All"
                [phoneValidation]="false"
                [numberFormat]="PhoneNumberFormat.International"
                [inputId]="'phone'"
                (onChange)="onInputChange($event)"
              >
              </intl-telephone-input>

            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="dni">DNI</label>
                <input maxlength="8" type="text" class="form-control" id="dni" [(ngModel)]="lead_to_create.dni">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="servicio_interes">Servicio de Interés</label>
                <ng-select  [(ngModel)]="lead_to_create.servicio_interes">
                    <ng-option value="Envíos nacional">Envíos nacional</ng-option>
                    <ng-option value="Imprenta digital">Imprenta digital</ng-option>
                    <ng-option value="Imprenta comercial">Imprenta comercial</ng-option>
                    <ng-option value="Ebox">Ebox</ng-option>
                    <ng-option value="Embalaje">Embalaje</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="ciudad">Ciudad:</label>
                <input type="text" class="form-control" id="ciudad" [(ngModel)]="lead_to_create.ciudad">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="concesionario">Centros</label>
                <ng-select (change)="look_for_sellers()" bindValue="id" bindLabel="name" [items]="concesionarios"
                    class="js-example-basic-multiple" [(ngModel)]="lead_to_create.concesionario_id">
                </ng-select>
            </div>
        </div>
    
        <div class="col-12 col-md-6" *ngIf="franquicia_lead == false">
            <div class="form-group">
                <label for="plataforma">{{user_id}} Plataforma</label>
                <ng-select [(ngModel)]="lead_to_create.plataforma">
                    <ng-option value="Landing">Landing</ng-option>
                    <ng-option value="RRSS">Redes Sociales</ng-option>
                    <ng-option value="Facebook">Facebook</ng-option>
                    <ng-option value="Instagram">Instagram</ng-option>
                    <ng-option value="Tienda">Tienda</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="franquicia_lead == true">
            <div class="form-group">
                <label for="plataforma">Plataforma</label>
                <ng-select [(ngModel)]="lead_to_create.plataforma">
                    <ng-option value="Franquicias" selected>Franquicias</ng-option>
                </ng-select>
            </div>
        </div>
        
    </div>




    <div class="form-group" *ngIf="user_type_id != 2">
        <label for="concesionario">Vendedor</label>
        <ng-select  bindValue="id" bindLabel="name" [items]="sellers_disponibles"
            class="js-example-basic-multiple" [(ngModel)]="lead_to_create.seller_id">
        </ng-select>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="ciudad">Nombre de empresa:</label>
                <input type="text" class="form-control" id="name_empresa" [(ngModel)]="lead_to_create.name_empresa">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="ciudad">Rubro de empresa:</label>
                <input type="text" class="form-control" id="rubro_empresa" [(ngModel)]="lead_to_create.rubro_empresa">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="ciudad">Tipo empresa</label>
                <input type="text" class="form-control" id="tipo_empresa" [(ngModel)]="lead_to_create.tipo_empresa">
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="cargo_contacto">Cargo contacto</label>
                <input type="text" class="form-control" id="cargo_contacto" [(ngModel)]="lead_to_create.cargo_contacto">
            </div>
        </div>
    </div>


    <div class="col-12">
        <div class="form-group">
            <label for="comentarios">Comentarios</label>
            <textarea [(ngModel)]="lead_to_create.comentarios" class="form-control" id="comentarios" cols="30"
                rows="10"></textarea>
        </div>
    </div>
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading? true:null" class="btn btn-primary" (click)="onSubmit()">Agregar</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>

</div>