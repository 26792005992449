import { Component, Input, OnInit, AfterViewInit, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-dashboard-usuarios-dias-conexion',
  templateUrl: './dashboard-usuarios-dias-conexion.component.html',
  styleUrls: ['./dashboard-usuarios-dias-conexion.component.scss']
})
export class DashboardUsuariosDiasConexionComponent implements OnInit, AfterViewInit {



  @Input() single
  @Input() designatedTotal

  width: number = 500
  height: number = 400
  view: any[]


  // options
  showLegend: boolean = true;
  showLabels: boolean = true;

  colorScheme = {
    domain: ['#f26171', '#f04255', '#ff102a', '#aa0013', '#67020d']
  };

  constructor(private elementRef: ElementRef) {

    var user_type_id = JSON.parse(localStorage.user).user_type_id;

    
    var single = []


    if (user_type_id == 2) {
       single = [
        {
          "name": "Jeremías Smith",
          "value": 30
        }
      ];
    } else {
       single = [
        {
          "name": "Ricardo Garcia Chavez",
          "value": 20
        },
        {
          "name": "Vendedor 1",
          "value": 28
        }
      ];
    }





    Object.assign(this, { single });
  }
  ngOnInit(): void {
    if (this.width < 768) {
      this.height = 800
    } else {
      this.height = 400
    }
    this.view = [this.width, this.height]
  }


  ngAfterViewInit() {
    const nativeElement = this.elementRef.nativeElement;
    const width = nativeElement.offsetWidth;
    this.width = width

    if (this.width < 768) {
      this.height = 800
    } else {
      this.height = 400
    }

    this.view = [this.width, this.height]
  }


  onSelect(event) {
    console.log(event);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = this.elementRef.nativeElement.offsetWidth;
    this.width = width
    if (this.width < 768) {
      this.height = 800
    } else {
      this.height = 400
    }
    this.view = [this.width, this.height]
  }


}