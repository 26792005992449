<div class="container mt-5 mb-5">
    <div class="form-group col-12">
        <h2>Llamar</h2>
    </div>
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Número de teléfono</h5>
                    <p class="card-text">{{celular_lead}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-12">

            <div class="form-group">
                <label for="comentario">Resumen de Llamada:</label>
                <textarea class="form-control" id="comentario" rows="3" placeholder="Escribe aquí tu comentario..." [(ngModel)]="resumenLlamada"></textarea>
            </div>

            <div class="col-7 row">
                <div class="col-6">
                    <button class="btn btn-primary" [disabled]="!resumenLlamada" (click)="guardarLlamada()">Guardar</button>
                </div>
                <div class="col-6">
                    <button class="btn btn-success" (click)="programar_llamada()">Agendar Llamada<i class="icofont icofont-ui-calendar"></i></button>
                </div>
            </div>


        </div>
    </div>
</div>
