import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Router } from "@angular/router";
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../src/environments/environment';
import { lastValueFrom, Subscription } from 'rxjs'
// import { TranslateService } from '@ngx-translate/core';
const Swal = require('sweetalert2')

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private http: HttpClient, public router: Router
  ) {
    /** VERIFICANDO SI ES VENDEDOR */
    if (localStorage.user) {
      if (JSON.parse(localStorage.user).user_type_id == 2) {
        if (localStorage.tienda_selected === undefined) {
          this.redirect()

        }

      }
    }

  }

  async redirect() {
    const data = new FormData()
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}UserAPI/get_tiendas_asigned`, data));
    
    if (response.success) {
      if (response.tiendas.length == 1) {
        localStorage.setItem('tienda_selected', response.tiendas[0].id)
        this.router.navigate(['/'])
      } else {
        this.router.navigate(['/asesor-select-tienda'])
        Swal.fire('Error', 'Contáctese con el administrador', 'error')
      }
    } else {
      console.log(response.message)
    }
  }





}
