import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { Subscription, lastValueFrom } from 'rxjs';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-history-lead',
  templateUrl: './history-lead.component.html',
  styleUrls: ['./history-lead.component.scss']
})
export class HistoryLeadComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,    private http: HttpClient,
    ) {}

    @Input() private lead_id
    @Input() private jwt

  async ngOnInit() {

    const data = new FormData()

    data.append('lead_id', this.lead_id)
    data.append('jwt', this.jwt)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/insert_comunicacion_call_by_lead_id`, data))


    if (response.success = "true") {
      console.log(response);
    }





  }

}
