<h5>Historial de actividades del usuario</h5>
<ngx-charts-heat-map

  [scheme]="colorScheme"
  [legend]="legend"
  [legendPosition]="'below'"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxis]="xAxis"
  [yAxis]="yAxis"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [results]="multi"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  >
</ngx-charts-heat-map>