<div id="chart">
    <apx-chart
      [series]="chartOptions.series"
      [colors]="chartOptions.colors"
      [chart]="chartOptions.chart"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [labels]="chartOptions.labels"
      [stroke]="chartOptions.stroke"
      [title]="chartOptions.title"
      [dataLabels]="chartOptions.dataLabels"
      [fill]="chartOptions.fill"
      [tooltip]="chartOptions.tooltip"
    ></apx-chart>
</div>