import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UsersService } from '../../logueo/users.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
const Swal = require('sweetalert2')
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  public loggin_type

  private routeSub: Subscription;

  constructor(private fb: FormBuilder, public userService: UsersService,
    private route: ActivatedRoute, private http: HttpClient) {
    this.loggin_type = environment.loggin_type
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  async ngOnInit() {
    console.log(this.loggin_type)
    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      const data = new FormData()
      data.append('token', token)
      const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ClientAPI/force_login`, data))
      if (response.success) {
        this.userService.login(response.user.email, "Pulp@MasterKey");
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          text: 'Token inválido',
        });
      }
    }
  }

  showPassword() {
    this.show = !this.show;
  }


  // Simple Login
  login() {

    this.userService.login(this.loginForm.value['email'], this.loginForm.value['password']);
    // this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password']);
  }

  openLoginPopup() {
    console.log('openLoginPopup')


    const tenantid = "274c9cef-1eac-40b7-a1a5-84714f265a7c";
    const appid = "b4df7e32-860a-46dd-a88b-12f61ee6bd5a";
    const loginUrl = `https://login.microsoftonline.com/${tenantid}/oauth2/v2.0/authorize`;
    const redirectUri = "https://pruebas.pulpa.digital/login/public/login-success.php";
    const responseType = "token";
    const scope = "https://graph.microsoft.com/User.Read";
    const state = Math.random().toString(36).substring(7);

    const loginParams = {
      client_id: appid,
      redirect_uri: redirectUri,
      response_type: responseType,
      scope: scope,
      state: state
    };

    const loginUrlWithParams = `${loginUrl}?${new URLSearchParams(loginParams).toString()}`;

    const loginPopup = window.open(loginUrlWithParams, "loginPopup", "width=500,height=600");

    const intervalId = setInterval(() => {
      if (!loginPopup.closed) {
        clearInterval(intervalId);
        handleLogin();
      }
    }, 1000);


    const handleLogin = async () => {

      console.log(loginPopup);

      const popupUrl = loginPopup.location.href;

      // Obtiene los parámetros de la URL
      const urlParams = new URLSearchParams(popupUrl.split("#")[1]);

      // Obtiene el valor del parámetro access_token
      const accessTokenPromise = urlParams.get("access_token");

      try {
        // Esperar a que se resuelva la promesa
        const accessToken = await accessTokenPromise;

        console.log(urlParams);
        console.log(`Token de acceso: ${accessToken}`);

        // Guardar el token en el localStorage
        localStorage.setItem("access_token", accessToken);

        // Cerrar la ventana emergente
        loginPopup.close();

        // Opcionalmente, redirigir a una página de éxito
        window.location.href = `https://honda2w.pulpa.digital/auth/login?token=${accessToken}`;
      } catch (error) {

        // console.error(error);

        Swal.fire({
          icon: 'error',
          title: 'Error de Logueo',
          text: error
        })
      }
    }

  }

}
