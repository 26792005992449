<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5> Reprogramar nueva llamada </h5>
        </div>
        <div class="card-body">

          <ng-template #modalContent let-close="close">
            <div class="modal-header">
              <h5 class="modal-title"> Citas Pendientes: {{ fecha_inicio | date: 'yyyy-MM-dd' }}</h5>
              <button type="button" class="btn-close" (click)="close()">
                
              </button>
            </div>
            <div class="modal-body">
              <div class="card">

                <div class="card-header calender-btn">
                  <h5>
                 
                    <button class="btn btn-primary pull-right" (click)="addEvent()"> Reprogramar Nueva Cita <i class="fa fa-plus"></i>  </button>
                    <div class="clearfix"></div>
                  </h5>
                </div>
                <ng-template *ngIf="fechasByDay.length != 0; then thenConregistros else SinRegsitros"></ng-template>

                  <div class="card-body" >
                    <ng-template #thenConregistros>
                      <div class="table-responsive">
                        <table class="table table-bordered calendar-event">
  
                            <thead>
                              <tr>
                           
                                <th> ID Lead</th>
                                <th> Cliente </th>
                                <th> Fecha de cita </th>
                                <th> Acciones </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let event of fechasByDay; let index = index">
              
                         
                                <td>
                                  <p>{{event.lead_id}}</p>
                                </td>
                                <td>
                                  <p>{{event.title}}</p> 
                                </td>
                    
              
                                <td>
                        
                                  <input class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.start" 
                                    (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true"
                                    dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set">
                                </td>
                    
                                <td>
                                  <button class="btn btn-danger m-2" (click)="fechasByDay.splice(index, 1); eliminar_call_again(event.id, index); refresh.next();">
                                    Eliminar Cita <i class="fa fa-trash"></i>
                                  </button>
              
                                </td>
              
                              </tr>
              
                            </tbody>
            
                        </table>
                    
            
                        <button class="btn btn-info m-2" (click)="guardar_call_again();">
                          Guardar <i class="fa fa-check"></i>
                        </button> 

                      </div>

                    </ng-template>

                    <ng-template #SinRegsitros>

                      <center>
                        <div style="margin-top: 20px;color: rgb(155, 155, 155);">
                          <h3  class="mt-7"> SIN CITAS AGENDADAS </h3>
                      </div>
                      </center>

                    </ng-template>

                  </div>
                
              </div>
            </div>
          </ng-template>

          <div class="row text-center">
            <div class="col-md-4">
              <div class="btn-showcase">
                <button type="button" class="fc-prev-button fc-button fc-state-default btn btn-light fc-corner-left"
                  mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                  (viewDateChange)="activeDayIsOpen = false">
                  <i class="fa fa-angle-left"></i></button>
                <button type="button" class="fc-next-button fc-button fc-state-default btn btn-light fc-corner-right"
                  mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="activeDayIsOpen = false">
                  <i class="fa fa-angle-right"></i></button>
                <div class="btn btn-light" mwlCalendarToday [(viewDate)]="viewDate">
                  Today
                </div>
              </div>
            </div>
            
            <div class="col-md-4">
              <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>

            <div class="fc-right col">
              <div class="fc-button-group">
                <button class="fc-month-button fc-button fc-state-default btn btn-light fc-corner-left fc-state-active"
                  (click)="view = CalendarView.Month" [class.active]="view === CalendarView.Month">
                  Mes
                </button>
                <button class="fc-month-button fc-button fc-state-default btn btn-light fc-corner-left"
                  (click)="view = CalendarView.Week" [class.active]="view === CalendarView.Week">
                  Semana
                </button>
                <button class="fc-month-button fc-button fc-state-default btn btn-light fc-corner-left"
                  (click)="view = CalendarView.Day" [class.active]="view === CalendarView.Day">
                  Día
                </button>
              </div>
            </div>
          </div>
          <br>
          <div [ngSwitch]="view">

            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
              (eventTimesChanged)="eventTimesChanged($event)"
              [dateFilter]="dateFilter" (beforeViewRender)="beforeMonthViewRender($event)"
              >
            </mwl-calendar-month-view>

            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" (dayClicked)="dayAndHoursClicked($event.day)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>

            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
            
          </div>
        </div>
      </div>

    </div>
  </div>
</div>