import { Component, AfterViewInit, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs'
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { CampusService } from '../../../app/shared/services/campus.service';

@Component({
  selector: 'app-contact-center',
  templateUrl: './contact-center.component.html',
  styleUrls: ['./contact-center.component.scss']
})

export class ContactCenterComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router, private campusService: CampusService) { }

  async ngOnInit() {
    console.log('entro a contact center')

    const result = <any>await this.generateView()

    
    if (Array.isArray(result)) {

     this.router.navigate(['contact-center/tienda'])

    } else {
      console.log(result)
      if (result) {
        this.router.navigate([result])
      }else{
        this.router.navigate([''])
      }

    }
  }

  async generateView() {
    try {
      console.log('generateview')
      return await this.campusService.get_list_of_campus()
    } catch (error) {
      console.log('Error :',error.message)
    }
  }

}
