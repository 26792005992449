<app-breadcrumb [title]="'Calender'" [items]="['Apps', 'Calender']" [active_item]="'Calender'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Basic Calender</h5>
        </div>
        <div class="card-body">
          <ng-template #modalContent let-close="close">
            <div class="modal-header">
              <h5 class="modal-title">Event action occurred</h5>
              <button type="button" class="btn-close" (click)="close()">
                
              </button>
            </div>
            <div class="modal-body">
              <div>
                Action:
                <pre>{{ modalData?.action }}</pre>
              </div>
              <div>
                Event:
                <pre>{{ modalData?.event | json }}</pre>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" (click)="close()">OK</button>
            </div>
          </ng-template>
          <div class="row text-center">
            <div class="col-md-4">
              <div class="btn-showcase">
                <button type="button" class="fc-prev-button fc-button fc-state-default btn btn-light fc-corner-left"
                  mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                  (viewDateChange)="activeDayIsOpen = false">
                  <i class="fa fa-angle-left"></i></button>
                <button type="button" class="fc-next-button fc-button fc-state-default btn btn-light fc-corner-right"
                  mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="activeDayIsOpen = false">
                  <i class="fa fa-angle-right"></i></button>
                <div class="btn btn-light" mwlCalendarToday [(viewDate)]="viewDate">
                  Today
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>
            <div class="fc-right col">
              <div class="fc-button-group">
                <button class="fc-month-button fc-button fc-state-default btn btn-light fc-corner-left fc-state-active"
                  (click)="view = CalendarView.Month" [class.active]="view === CalendarView.Month">
                  Month
                </button>
                <button class="fc-month-button fc-button fc-state-default btn btn-light fc-corner-left"
                  (click)="view = CalendarView.Week" [class.active]="view === CalendarView.Week">
                  Week
                </button>
                <button class="fc-month-button fc-button fc-state-default btn btn-light fc-corner-left"
                  (click)="view = CalendarView.Day" [class.active]="view === CalendarView.Day">
                  Day
                </button>
              </div>
            </div>
          </div>
          <br>
          <div [ngSwitch]="view">

            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
              (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>

            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>

            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
            
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header calender-btn">
          <h5>
            Edit events
            <button class="btn btn-primary pull-right" (click)="addEvent()">
              Add new
            </button>
            <div class="clearfix"></div>
          </h5>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered calendar-event">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Primary color</th>
                  <th>Secondary color</th>
                  <th>Starts at</th>
                  <th>Ends at</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let event of events; let index = index">
                  <td>
                    <input type="text" class="form-control" [(ngModel)]="event.title" (keyup)="refresh.next()">
                  </td>
                  <td>
                    <input type="color" [(ngModel)]="event.color.primary" (change)="refresh.next()">
                  </td>
                  <td>
                    <input type="color" [(ngModel)]="event.color.secondary" (change)="refresh.next()">
                  </td>
                  <td>
                    
                    <input class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.start"
                      (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true"
                      dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set">
                  </td>

                  <td>
                    <input class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.end"
                      (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true"
                      dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set">
                  </td>
                  <td>
                    <button class="btn btn-danger" (click)="events.splice(index, 1); refresh.next()">
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>