import { Component, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Subscription, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-configuracion-crm',
  templateUrl: './configuracion-crm.component.html',
  styleUrls: ['./configuracion-crm.component.scss']
})
export class ConfiguracionCrmComponent implements OnInit {

  private jwt
  funcionalidades: []
  toggleValue: boolean = false;


  constructor(private http: HttpClient) { 
    this.jwt = JSON.parse(localStorage.user).jwt
  }


  async toggleChanged(id, habilitada) {

    var value =  this.toggleValue;

    console.log(id);
    console.log(habilitada);
    console.log('Valor del toggle:', value);

    const data = new FormData()

    data.append('jwt', this.jwt)
    data.append('funcionalidad_id', id)
    data.append('valor', habilitada.toString())

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ConfigurationAPI/actualizar_funcionalidad`, data))

    if (response.success) {

      console.log(this.funcionalidades)
    }
    
    // Puedes realizar otras acciones aquí cuando cambia el estado del toggle
  }

  async ngOnInit(){

    const data = new FormData()
    data.append('jwt', this.jwt)

    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ConfigurationAPI/get_configuration`, data))
    if (response.success) {
      this.funcionalidades = response.funcionalidades

      console.log(this.funcionalidades)
    }

  }

}
